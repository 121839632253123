<template>
    <v-layout justify-center>
        <v-responsive max-width="800px" width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="연혁">
                <template #add-button>
                    <history-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </history-form>
                </template>
            </list-heading>

            <history-list-search v-bind="{}" flat outlined />

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

                <template #[`item.index`]="{ item, value }">
                    <history-index-edit v-bind="{ value: item }" @update="update">
                        {{ value }}
                    </history-index-edit>
                </template>

                <template #[`item.actions`]="{ item }">
                    <history-form :value="item" @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon v-bind="{ attrs }" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </history-form>

                    <v-btn small text icon @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="20" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page: page == 1 ? undefined : page } })"></v-pagination> </template
            ></v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { initDataTableHeaders, inputAttrs } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import HistoryForm from "@/components/console/archiving/history/history-form.vue";
import HistoryIndexEdit from "@/components/console/archiving/history/history-index-edit.vue";
import HistoryListSearch from "@/components/console/archiving/history/history-list-search.vue";

const headers = initDataTableHeaders([
    { text: "#", value: "index", width: 20 },
    { text: "년도", value: "year", width: 20 },
    { text: "월", value: "month", width: 20 },
    { text: "내용", value: "content", width: +150, cellClass: "white-space-pre-line" },
    { text: "Actions", value: "actions", width: 20 },
]).map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "left", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ListHeading,
        HistoryForm,
        HistoryIndexEdit,
        HistoryListSearch,
    },
    data: () => ({
        loading: false,
        inputAttrs,
        headers,

        histories: [],
        summary: { totalCount: 0 },
        limit: 10,
    }),
    computed: {
        items() {
            return [...this.histories];
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        page() {
            return +(this.$route.query.page || 1);
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        query() {
            let { ...query } = this.$route.query;
            return query;
        },
    },
    async mounted() {
        await this.search();
    },
    methods: {
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit } = this;

                let { summary, histories } = await api.console.archiving.history.gets({
                    headers: { skip, limit },
                    params: this.query,
                });

                this.histories = histories;
                this.summary = summary;
            } finally {
                if (this.loading) this.loading = false;
            }
        },
        async update(item) {
            if (this.loading) return;
            this.loading = false;
            try {
                await api.console.archiving.history.put(item);
                await this.search();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        edit(item) {
            this.$router.push(`${this.$route.path}/${item._id}`);
        },
        async remove(item) {
            if (this.loading) return;
            this.loading = false;
            try {
                if (confirm("삭제하시겠습니까?")) {
                    await api.console.archiving.history.delete(item);
                    await this.search();

                    alert("삭제되었습니다.");
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        query() {
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .cursor-pointer {
        cursor: pointer;
    }
}
</style>
